import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "o auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      border: "1px solid tan",
      right: "40px",
      top: 0,
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto",
      },
    },
  },
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid tan",
    "&.nobottom":{
      borderBottom: 0,

    },
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "tomato tomato transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "tan",
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent tomato tomato",
      },
    },
  },
  timeLineBlank: {
    padding: ".1rem",
    position: "relative",
    margin: "1rem 1rem 1rem 1rem",
    clear: "both",
    
  },
  timeLineYear: {
    textAlign: "center",
    maxWidth: "9.375rem",
    margin: "0 3rem 0 auto",
    fontSize: "1.8rem",
    color: "#fff",
    background: "tomato",
    lineHeight: 1,
    padding: "0.5rem 1rem",
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        float: "none",
        margin: "0 auto",
      },
      "&:nth-of-type(2n):before": {
        display: "none",
      },
    },
  },
  heading: {
    color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase",
  },
  subHeading: {
    color: "#fff",
    padding: 0,
    textTransform: "uppercase",
  },
  body1: {
    color: "tomato",
  },
  subtitle1: {
    color: "tan",
  },
  listedWork: {
    display: 'flex',
    justifyContent: 'center', // Centers the list itself
    alignItems: 'center',
    textOrientation: 'upright',
    flexDirection: 'column',
    textAlign:'center',
    "& > ul": {
      listStyleType: 'disc', // Ensures markers are disc type
      paddingLeft: '0', // Removes default padding
      marginLeft: '0', // Removes default margin
      textAlign: 'left', // Ensures the text is aligned left within the centered list
      "& > ul": {
        paddingLeft: '10', // Removes default padding

      }
    }
  }
  
}));

const Resume = () => {
  const classes = useStyles();
  return (
    <Box component="header" className={classes.mainContainer}>
      <Typography variant="h4" align="center" className={classes.heading}>
        Working Experience
      </Typography>
      <Box component="div" className={classes.timeLine}>
        <Year year={2007}/>
        <Workplace job="Volunteer Contributor" place="Wikipedia">
          <Typography variant="body1">
            Gained experience in translation (English-Russian and Russian-English).
          </Typography>
        </Workplace>
        <Year year={2009}/>
        <Workplace job="Freelance Translator" place="Freelance">
          <Typography variant="body1">
            Provided freelance translation and copywriting services.
          </Typography>
        </Workplace>
        <Year year={2011}/>
        <Blank/>
        <Year year={2014}/>
        <Workplace job="Editor, Copywriter, Translator" place="Questlix">
          <Typography variant="body1" className={classes.listedWork}>
            <div>Responsibilities:</div>
            <ul>
              <li><b>SEO Copywriting</b>
                <ul>
                  <li>Writing product reviews</li>
                  <li>Creating buyer's guides</li>
                </ul>
              </li>
              <li><b>Translation</b>
                <ul>
                  <li>English to Russian</li>
                  <li>Russian to English</li>
                </ul>
              </li>
              <li><b>Proofreading</b></li>
              <li><b>Supervision</b>
                <ul>
                  <li>Supervision of English Copywriters</li>
                </ul>
              </li>
              <li><b>Research</b>
                <ul>
                  <li>Web research</li>
                  <li>SEO research and analysis</li>
                </ul>
              </li>
              <li><b>Data Processing</b></li>
              <li><b>Registrar and Host Procurement</b></li>
              <li><b>CMS</b>
                <ul>
                  <li>Deployment</li>
                  <li>Administration</li>
                </ul>
              </li>
              <li><b>Development</b>
                <ul>
                  <li>Web applications</li>
                  <li>Chrome extensions</li>
                  <li>WordPress plugins</li>
                  <li>JS scripts</li>
                  <li>Ejabberd plugin</li>
                  <li>Jitsi server</li>
                </ul>
              </li>
            </ul>

          </Typography>
        </Workplace>
        <Year year={2018}/>
        <Workplace job="Editor, Copywriter, Translator" place="Diamond Sky">
          <Typography variant="body1" className={classes.listedWork}>
            Responsibilities:
            <ul>
              <li>SEO copywriting</li>
              <li>Translation and interpretation</li>
              <li>Proofreading</li>
              <li>Supervision of copywriters</li>
              <li>Web and SEO research</li>
              <li>CMS deployment</li>
              <li>PHP development</li>
            </ul>
          </Typography>
        </Workplace>
        <Year year={2019}/>
        <Workplace job="Technical Writer" place="Creatio" nobottom={true}>
          <Typography variant="body1"  className={classes.listedWork}>
            Responsibilities:
            <ul>
              <li>Maintenance and creation of English user documentation</li>
              <li>Translation of user and developer documentation (Russian-English)</li>
              <li>Proofreading</li>
            </ul>
          </Typography>
        </Workplace>
        <Year year={2024}/>
        <Box component="div" className={classes.timeLineBlank}/>
        <Workplace job="Localization Engineer" place="Creatio">
        <Typography variant="body1"  className={classes.listedWork}>
            Responsibilities:
            <ul>
              <li>Ukrainian product localization</li>
              <li>Coordination and maintenance of multilanguage localization</li>
            </ul>
          </Typography>
        </Workplace>
      </Box>
    </Box>
  );

  function Workplace({children, job, place, nobottom = false}) {
    return (
      <Box component="div" className={classes.timeLineItem+(nobottom? ' nobottom':'')}>
        <Typography variant="h5" align="center" className={classes.subHeading}>
          {job}
        </Typography>
        <Typography variant="body1" align="center" className={classes.body1}>
          {place}
        </Typography>
        <Typography variant="subtitle1" align="center" className={classes.subtitle1}>
          {children}
        </Typography>
      </Box>
    );
  }

  function Blank() {
    return (
      <><Box component="div" className={classes.timeLineBlank}>

      </Box>
      <Box component="div" className={classes.timeLineBlank}>

      </Box></>
    )
  }

  function Year({year}) {
    return (
      <Typography variant="h2" className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
        {year}
      </Typography>
    );
  }
};

export default Resume;
